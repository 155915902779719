<div class="sdds-card welcome-page">
    <div class="card-img">
        <img class="sdds-card-img" src="https://dw1u92ia6bs84.cloudfront.net/Images/1e70c35fb37b46548526b7f58c1e39f4.jpg">
    </div>
    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Welcome to Truck Manager!</h6>
        <h6 class="sdds-card-sub-headline">A simple angular front-end project.</h6>
    </div>

    <div class="sdds-divider-light-border-top"></div>
    <div class="sdds-card-body">This simple project aims to be reference to future angular front-end applications inside Scania! 
        The project contains best practices, folder structures, CICD references, and more. All with SDDS (Scania Digital Design System). This simple project, 
        has a feature in the TRUCKS tab, to be of reference on how to implement CRUD using angular! Any futher information, or suggestions, can be adressed to 
        virtual cloud team.
    </div>
</div>