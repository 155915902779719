import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { TrucksComponent } from './trucks/trucks.component';
import { EditTruckComponent } from './trucks/edit-truck/edit-truck.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { EditTruckInlineComponent } from './trucks/edit-truck-inline/edit-truck-inline.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    TrucksComponent,
    EditTruckComponent,
    ConfirmationModalComponent,
    EditTruckInlineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add the Schema
})
export class AppModule { }
