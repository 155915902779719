import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditTruckComponent } from './trucks/edit-truck/edit-truck.component';
import { TrucksComponent } from './trucks/trucks.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  { path: '', redirectTo:'welcome', pathMatch:'full' },
  { path: 'trucks/edit/:id', component: EditTruckComponent, pathMatch:'full' },
  { path: 'welcome', component: WelcomeComponent, pathMatch:'full' },
  { path: 'trucks', component: TrucksComponent, pathMatch:'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
